import { Component, OnInit } from "@angular/core";
import { SOMETHING_WENT_WRONG } from "./shared/constants";
import { LaunchBarData } from "./shared/models";
import { RdSiderMenuItem } from "@zs-ca/angular-cd-library";
import { forkJoin } from "rxjs";
import { CustomIconService, DataFetchService } from "./shared/services";
import { AuthService } from "./shared/services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Cohort Builder";

  errorMessage = "";

  isLoading = false;

  launchBarData?: LaunchBarData;

  sideBarMenuItemsList: RdSiderMenuItem[] = [];

  constructor(
    private dataFetchService: DataFetchService,
    private auth: AuthService,
    private customIconService: CustomIconService
  ) {
    this.customIconService.addCustomIconsToNzLibrary();
  }

  ngOnInit(): void {
    this.fetchLaunchBar();
  }

  fetchLaunchBar() {
    this.isLoading = true;
    const launchBar$ = this.dataFetchService.getLaunchBar();
    const user$ = this.dataFetchService.getUserDetails();
    forkJoin([launchBar$, user$]).subscribe({
      next: ([launchBarResponse, userDetailsResponse]) => {
        // if (launchBarResponse?.applications?.applications?.length) {
        this.launchBarData = { ...launchBarResponse.applications };
        if (userDetailsResponse) {
          this.auth.setUserDetails(userDetailsResponse);
          this.sideBarMenuItemsList = this.auth.getAvailableScreens();
          this.errorMessage = "";
        }
        // } else {
        //   this.errorMessage = SOMETHING_WENT_WRONG;
        // }
        this.isLoading = false;
      },
      error: (error: any) => {
        if (error?.status === 401) {
          this.errorMessage = "";
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
          this.isLoading = false;
        }
      },
    });
  }
}
