<rd-sider
  [siderMenuItems]="menuItemsList"
  [collapsible]="false"
  [applicationList]="siderData?.applications || []"
  [showAppsList]="!!siderData?.applications?.length"
  [logoutUrl]="siderData?.signOut || ''"
  [idmAccountUrl]="siderData?.myAccount || ''"
  [expirationMessage]="siderData?.expiry"
  (brandClick)="handleBrandClick()"
  (helpClick)="toggleSupportModal(true)"
>
  <img rd-sider-brand src="assets/images/zaidyn-logo.svg" alt="ZAIDYN" />
</rd-sider>

<rd-modal
  *ngIf="showHelpModal"
  [isVisible]="showHelpModal"
  [isCloseable]="true"
  [isMaskClosable]="true"
  (modalCloseEvent)="toggleSupportModal(false)"
>
  <div rdModalTitle>Help Center</div>
  <div rdModalContent class="rd-sider__modal-content">
    <div class="help__section contact-list">
      <h1 class="help__section-title">Technical Support</h1>
      <div class="contact-list__container">
        <div
          *ngFor="let contact of contactSupportList"
          class="help__item contact-list__item"
        >
          <span class="item__label"> {{ contact?.label }} </span>
          <a class="item__value item__value--link" [href]="contact.href">
            {{ contact.value }}
          </a>
        </div>
      </div>
    </div>
    <div class="help__section">
      <h1 class="help__section-title">Release Version</h1>
      <div class="help__item">
        <p class="item__value">{{ releaseVersion }}</p>
      </div>
    </div>
  </div>
  <div rdModalFooter>
    <rd-button
      btnText="Close"
      btnType="default"
      (btnClick)="toggleSupportModal(false)"
    ></rd-button>
  </div>
</rd-modal>
