export interface HomeModalConfig {
  modalName: string;
  isVisible: boolean;
  modalTitle: string;
  columnDetail?: any;
  modalWidth: string;
  footerButton: ButtonConfig[];
}

export interface ButtonConfig {
  btnText: string;
  btnType: any;
  btnStyle: any;
  isDisabled: () => boolean;
  handler: () => void;
  isLoading: () => boolean;
}

export const HOME_KPI = [
  {
    label: 'Cohort Library',
    key: 'cohortLibrary',
    value: 0,
  },
  {
    label: 'Workspace',
    key: 'workspace',
    value: 0,
  },
];

export const HOME_VIEW = [
  {
    label: '',
    value: 'list',
    icon: {
      type: 'custom:list-view',
      theme: 'fill',
    },
  },
  {
    label: '',
    value: 'tile',
    icon: {
      type: 'custom:tile-view',
      theme: 'fill',
    },
  },
];

export const DOWNLOAD_TYPE = [
  {
    label: 'Patient IDs (.CSV)',
    value: 'patientIds',
  },
  {
    label: 'Cohort Summary (PDF)',
    value: 'cohortSummary',
  },
];

export const COHORT_TILE_DROPDOWN_ITEMS = () => [
  { label: 'Clone', value: 'clone', disabled: false },
  { label: 'Download', value: 'download', disabled: false },
  { label: 'Compare Cohort', value: 'compare', disabled: false },
  { label: 'Delete', value: 'delete', disabled: false },
];

export const DATA_SET_TABLE_COLUMNS = () => [
  {
    name: 'Dataset',
    key: 'dataset',
    dataType: 'checkbox',
    showHeaderCheckbox: true,
    indeterminate: false,
    checked: false,
    defaultSortOrder: null,
    align: 'left',
    type: 'text',
    width: '4%',
  },
  {
    name: 'Dataset Name',
    key: 'dataSource',
    dataType: 'string',
    defaultSortOrder: null,
    headerStyle: {
      'font-size': '14px',
      'font-weight': '600',
    },
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    ellipsis: true,
    type: 'text',
    width: '10%',
    showFilter: true,
    filterVisible: false,
    typeFilterIcon: 'custom:filter',
    isFilterActive: false,
  },

  {
    name: 'Patient Count',
    key: 'noOfPatients',
    dataType: 'number',
    defaultSortOrder: null,
    headerStyle: {
      'font-size': '14px',
      'font-weight': '600',
    },
    sortDirections: ['ascend', 'descend'],
    align: 'left',
    sortable: true,
    width: '10%',
    type: 'commaSeparatedNumber',
  },
];

export const EXPORT_VIEW_TABLE_COLUMNS = () => [
  {
    name: 'Dataset',
    key: 'data_src_displ_nm',
    dataType: 'string',
    headerStyle: {
      'font-size': '14px',
      'font-weight': '600',
    },
    align: 'left',
    ellipsis: true,
    type: 'text',
    setToLeft: true,
    width: '10%',
  },
  {
    name: 'Patient Count',
    key: 'no_of_patients',
    dataType: 'string',
    headerStyle: {
      'font-size': '14px',
      'font-weight': '600',
    },
    align: 'left',
    type: 'text',
    setToLeft: true,
    width: '7%',
  },
  {
    name: 'Last Updated On',
    key: 'last_updated',
    dataType: 'date',
    dateFormat: 'd MMM yyyy',
    headerStyle: {
      'font-size': '14px',
      'font-weight': '600',
    },
    align: 'left',
    type: 'text',
    setToLeft: true,
    width: '7%',
  },
  {
    name: 'Actions',
    key: 'actions',
    dataType: 'string',
    align: 'left',
    type: 'actions',
    width: '5%',
    setToRight: true,
    headerStyle: {
      'font-size': '14px',
      'font-weight': '600',
    },
    actionIcons: [
      {
        type: 'custom:copy',
        key: 'copy',
        title: 'Copy',
        theme: 'fill',
      },
      {
        type: 'custom:copyDisabled',
        key: 'copyDisabled',
        title: 'Copy',
        theme: 'fill',
      },
    ],
  },
];

export const COLLABORATOR_TABLE_COLUMNS = () => [
  {
    name: 'Name',
    key: 'collaboratorName',
    dataType: 'string',
    headerStyle: {
      'font-size': '14px',
      'font-weight': '600',
    },
    align: 'left',
    type: 'text',
    setToLeft: true,
    width: '25%',
  },
  {
    name: 'Edit',
    key: 'editLabel',
    dataType: 'checkbox',
    align: 'left',
    width: '7%',
  },
  {
    name: 'Clone',
    key: 'cloneLabel',
    dataType: 'checkbox',
    align: 'left',
    width: '7%',
  },
  {
    name: 'Actions',
    key: 'actions',
    dataType: 'string',
    align: 'left',
    type: 'actions',
    width: '10%',
    setToRight: true,
    headerStyle: {
      'font-size': '14px',
      'font-weight': '600',
    },
    actionIcons: [
      {
        type: 'custom:delete',
        key: 'delete',
        title: 'Delete',
        theme: 'fill',
      },
    ],
  },
];

export const SHARE_CONCEPT_RADIO_BUTTONS = [
  {
    label: 'Publish Cohort To Library',
    value: 'public',
    disabled: false,
  },
  {
    label: 'Add Collaborators',
    value: 'private',
    disabled: false,
  },
];
export const PUBLISH_OUTPUT_RADIO_BUTTONS = [
  {
    label: 'New Cohort',
    value: 'new',
    disabled: false,
  },
  {
    label: 'Replace Existing Cohort',
    value: 'replace',
    disabled: false,
  },
];
