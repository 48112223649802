import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TransactionManagerService {
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
  }

  /**
   * Function to call an API with DELETE method.
   */
  public delete(url: string): Observable<any> {
    const apiEndPoint = this.createEndPoint(url);
    return this.http.delete(apiEndPoint, this.httpOptions);
  }

  /**
   * Function to call an API with GET method.
   */
  public get(url: string, options?: any): Observable<any> {
    const apiEndPoint = this.createEndPoint(url);
    let updatedOptions = {};
    if (options !== undefined && Object.keys(options).length > 0) {
      updatedOptions = { ...this.httpOptions, ...options };
    } else {
      updatedOptions = this.httpOptions;
    }
    return this.http.get(apiEndPoint, updatedOptions);
  }

  /**
   * Function to call an API with POST method.
   */
  public post(
    url: string,
    body: any,
    options?: any,
    optionRequired: boolean = true
  ): Observable<any> {
    const apiEndPoint = this.createEndPoint(url);
    let updatedOptions = {};
    if (options !== undefined && Object.keys(options).length > 0) {
      updatedOptions = { ...this.httpOptions, ...options };
    } else {
      updatedOptions = this.httpOptions;
    }
    if (!optionRequired) {
      return this.http.post(apiEndPoint, body, { withCredentials: true });
    } else {
      return this.http.post(apiEndPoint, body, updatedOptions);
    }
  }

  /**
   * Function to call an API with POST method for file upload.
   */
  public postUpload(url: string, body: any, options?: any): Observable<any> {
    const apiEndPoint = this.createEndPoint(url);
    let updatedOptions = {};
    return this.http.post(apiEndPoint, body);
  }

  /**
   * Function to call an API with PUT method.
   */
  public put(url: string, body: any): Observable<any> {
    return this.http.put(url, body, this.httpOptions);
  }

  /**
   * Function to call an API with PATCH method.
   */
  public patch(url: string, body: any): Observable<any> {
    const apiEndPoint = this.createEndPoint(url);
    return this.http.patch(apiEndPoint, body, this.httpOptions);
  }

  private createEndPoint(url: string): string {
    return `${url}`;
  }
}
