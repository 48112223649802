export interface LaunchBarData {
  applications: {
    name: string;
    title: string;
    url: string;
  }[];
  expiry: { contactSupportLink: string; nonzs: string; zs: string };
  myAccount: string;
  signOut: string;
}

export interface LaunchBarApiResponse {
  applications: LaunchBarData;
  status: string;
}

export enum AppPages {
  HOME = "home",
  ADMIN = "admin",
}

export const SIDEBAR_MENU_ITEMS = {
  [AppPages.HOME]: {
    label: "Home",
    path: "/home",
    icon: "home",
  },
  [AppPages.ADMIN]: {
    label: "Admin",
    path: "/admin",
    icon: "custom:admin-user",
  },
};

export interface UserDetails {
  auth_status?: string;
  username?: string;
  user_role?: string[];
  session_cookie_details?: any;
  auth_cookie_details?: any;
  permissions?: string[];
  status?: string;
  email?: string;
  userId?: string;
}
