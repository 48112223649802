import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private notification: NzNotificationService) {}

  createNotification(type: string, message: any): void {
    this.notification.create(type, '', message, {
      nzClass: 'notification-' + type,
      nzStyle:
        type === 'error'
          ? {
              background: '#FFEDE9',
              border: '1px solid #FFB6A5',
              width: 'fit-content',
              'padding-right': '42px',
            }
          : type === 'success'
          ? {
              background: '#f6ffed',
              border: '1px solid #b7eb8f',
              width: 'fit-content',
              'padding-right': '42px',
            }
          : {
              background: '#e6f7ff',
              border: '1px solid #91d5ff',
              width: 'fit-content',
              'padding-right': '42px',
            },
      nzDuration: 4500,
    });
  }
  abbreviateNumber(num: number) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    if (num === undefined || num === null) {
      return 0;
    }
    return num.toString();
  }
}
