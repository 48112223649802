export const API_SUCCESS = 'SUCCESS';

export const SOMETHING_WENT_WRONG =
  'Something went wrong. Please try again later.';

export const COHORT_NO_DATA = 'No cohorts available';

export const EXPORT_NO_DATA = 'No Data Available';

export const COHORT_NAME_REQUIRED = 'Cohort Name is Required field.';

export const COHORT_ALREADY_EXISTS = 'Cohort Name already exists';

export const MIN_COHORT_NAME =
  'Enter cohort name to start with minimum 3 characters';

export const COHORT_NOT_DELETABLE =
  'Cohort cannot be cloned as the original cohort was deleted.';

export const COHORT_SPECIAL_CHAR =
  'Only alphanumeric characters, spaces, hyphens and underscores allowed for the Cohort Name';

export const MAX_LENGTH_COHORT_NAME =
  'Enter cohort name to start with maximum 50 characters';

export const DELETE_SUCCESS = 'Cohort deleted Successfully';

export const CLONE_SUCCESS = 'Cohort Cloned Successfully';

export const COPY_SUCCESS = 'S3 Location successfully copied to Clipboard!';

export const MAX_LENGTH_DESCRIPTION =
  'Cohort description exceeds maximum characters allowed';

export const SELECT_TA = 'Select Therapeutic Area';

export const SENT_FOR_APPROVAL = 'Cohort sent for approval';

export const SHARED_COHORT_MSG = 'Cohort Shared successfully with collaborator';

export const COHORT_NAME_EXISTS = 'Cohort title already exist';

export const INDEX_EVENT_MSG =
  'Please create an "Index Event" after adding a Concept to your inclusion to be able to create this filter.';

export const OBSERVATION_PERIOD_MSG =
  '"Observation Period" filter to your inclusion criteria to be able to create this filter.';
