export enum ApiEndpoints {
  LAUNCH_BAR = 'launch-bar',
  LOGIN = 'login',
  HOME_KPI = 'home-kpi',
  LOGOUT = 'logout',
  USER_DETAILS = 'user-details',
  FETCH_COHORTS = 'cohort_list',
  CHECK_LOCK_STATUS = 'check_edit_status',
  CLONE_COHORT = 'clone_cohort',
  DELETE_COHORT = 'delete_cohort',
  EXPORT_VIEW = 'export_cohort_view_list',
  DOWNLOAD_COHORT = 'download_cohort',
  APPROVER_LIST = 'approver_list',
  USER_LIST = 'user_list',
  PVT_SHARE_COHORT = 'pvt_share_cohort',
  PUBLISH_COHORT = 'publish_cohort',
  PUBLISHED_COHORT_DD = 'published_cohorts_dd',
  RELEASE_LOCK = 'release_edit_lock',
  VIEW_EDIT_COHORT = 'view_edit_cohort',
  FETCH_THERAPEUTIC_AREA = 'fetch_therapeutic_area',
  FETCH_CONCEPTS = 'fetch_concepts',
  FETCH_DATA_SOURCE = 'fetch_data_source',
  CREATE_COHORT = 'create_cohort',
  EDIT_COHORT = 'edit_cohort',
  CONCEPT_SET_LIST = 'concept_set_list',
  LISTING_CONCEPT_CODE = 'listing_concept_codes',
  UPLOAD_CSV = 'upload_csv',
}

export const localEndpointList: string[] = [
  // "launch-bar",
  // "user-details",
  // "home-kpi",
  // 'cohort_list',
];
