import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const headers = request.headers.set('Content-Type', 'application/json');
    const url = `${environment.config.serverUrl}/${request.url}`;
    const reqClone = request.clone({
      url,
      withCredentials: true,
    });

    return next.handle(reqClone);
  }
}
