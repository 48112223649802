<app-sider
  [menuItemsList]="sideBarMenuItemsList"
  [siderData]="launchBarData"
></app-sider>
<div class="app-layout">
  <app-header></app-header>
  <ng-container *ngIf="!isLoading; else loadingSpinner">
    <div class="app-content" *ngIf="!errorMessage">
      <router-outlet></router-outlet>
    </div>
    <div class="place-items-center-absolute" *ngIf="errorMessage">
      <rd-alert
        type="error"
        [closable]="false"
        [showIcon]="true"
        [message]="errorMessage"
      ></rd-alert>
    </div>
  </ng-container>
</div>

<ng-template #loadingSpinner>
  <div class="place-items-center-absolute">
    <rd-spin></rd-spin>
  </div>
</ng-template>
