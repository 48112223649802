import { Injectable } from '@angular/core';
import { ApiEndpoints } from '../constants';
import { Observable, Subject } from 'rxjs';
import { TransactionManagerService } from './transaction-manager.service';
import { LaunchBarApiResponse } from '../models';

@Injectable()
export class DataFetchService {
  constructor(private transactionManager: TransactionManagerService) {}

  getLaunchBar(): Observable<LaunchBarApiResponse> {
    return this.transactionManager.get(ApiEndpoints.LAUNCH_BAR);
  }

  getUserDetails(): Observable<any> {
    return this.transactionManager.get(ApiEndpoints.USER_DETAILS);
  }

  getHomeKPIData(): Observable<any> {
    return this.transactionManager.get(ApiEndpoints.HOME_KPI);
  }

  getCohortGridData(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.FETCH_COHORTS,
      payload
    );
  }

  getLockStatus(id: any): Observable<any> {
    return this.transactionManager.get(
      'cohort/' + ApiEndpoints.CHECK_LOCK_STATUS + '?chrt_id=' + id
    );
  }

  cloneCohort(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.CLONE_COHORT,
      payload
    );
  }

  releaseLock(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.RELEASE_LOCK,
      payload
    );
  }

  deleteCohort(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.DELETE_COHORT,
      payload
    );
  }

  exportView(id: any): Observable<any> {
    return this.transactionManager.get(
      'cohort/' + ApiEndpoints.EXPORT_VIEW + '?chrt_id=' + id
    );
  }

  downloadFile(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.DOWNLOAD_COHORT,
      payload
    );
  }

  getApproverList(cohortId: any): Observable<any> {
    return this.transactionManager.get(
      'cohort/' + ApiEndpoints.APPROVER_LIST + '?chrt_id=' + cohortId
    );
  }

  getCollaboratorsList(cohortId: any): Observable<any> {
    return this.transactionManager.get(
      'cohort/' + ApiEndpoints.USER_LIST + '?chrt_id=' + cohortId
    );
  }

  getPublishedCohorts(): Observable<any> {
    return this.transactionManager.get(
      'cohort/' + ApiEndpoints.PUBLISHED_COHORT_DD
    );
  }

  shareCohortPrivately(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.PVT_SHARE_COHORT,
      payload
    );
  }

  PublishCohort(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.PUBLISH_COHORT,
      payload
    );
  }

  viewEditCohort(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.VIEW_EDIT_COHORT,
      payload
    );
  }

  getTherapeuticArea(): Observable<any> {
    return this.transactionManager.get(
      'cohort/' + ApiEndpoints.FETCH_THERAPEUTIC_AREA
    );
  }

  getConcepts(): Observable<any> {
    return this.transactionManager.get('cohort/' + ApiEndpoints.FETCH_CONCEPTS);
  }

  getDataSource(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.FETCH_DATA_SOURCE,
      payload
    );
  }

  createCohort(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.CREATE_COHORT,
      payload
    );
  }

  editCohort(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.CREATE_COHORT,
      payload
    );
  }

  getConceptSetList(payload: any): Observable<any> {
    return this.transactionManager.post(
      'cohort/' + ApiEndpoints.CONCEPT_SET_LIST,
      payload
    );
  }
  uploadCsv(payload: any): Observable<any> {
    return this.transactionManager.postUpload(
      'cohort/' + ApiEndpoints.UPLOAD_CSV,
      payload
    );
  }

  getListingConceptCode(
    con_set_id: any,
    domain_id: any = 'Measurement'
  ): Observable<any> {
    return this.transactionManager.get(
      'concept/' +
        ApiEndpoints.LISTING_CONCEPT_CODE +
        '?con_set_id=' +
        con_set_id +
        '&domain_id=' +
        domain_id
    );
  }
}
