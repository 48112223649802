import { IconDefinition } from '@ant-design/icons-angular';

export const CUSTOM_ICONS: Array<IconDefinition> = [
  {
    name: 'black-delete',
    icon: `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M7 4V2H17V4H22V6H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="#454250"/>
</svg>
`,
  },
  {
    name: 'drag',
    icon: `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M5.08683 1.33333C4.74005 1.33333 4.40106 1.43616 4.11273 1.62882C3.8244 1.82148 3.59967 2.09531 3.46696 2.41569C3.33426 2.73607 3.29953 3.08861 3.36719 3.42872C3.43484 3.76883 3.60183 4.08125 3.84704 4.32646C4.09224 4.57166 4.40466 4.73865 4.74477 4.80631C5.08489 4.87396 5.43742 4.83924 5.7578 4.70653C6.07818 4.57382 6.35202 4.3491 6.54467 4.06076C6.73733 3.77243 6.84016 3.43344 6.84016 3.08666C6.84016 2.62165 6.65544 2.17568 6.32662 1.84687C5.99781 1.51805 5.55184 1.33333 5.08683 1.33333Z" fill="#DEDCDE"/>
  <path d="M5.08683 9.75333C6.05517 9.75333 6.84016 8.96833 6.84016 7.99999C6.84016 7.03165 6.05517 6.24666 5.08683 6.24666C4.11849 6.24666 3.3335 7.03165 3.3335 7.99999C3.3335 8.96833 4.11849 9.75333 5.08683 9.75333Z" fill="#DEDCDE"/>
  <path d="M5.08683 14.6667C6.05517 14.6667 6.84016 13.8817 6.84016 12.9133C6.84016 11.945 6.05517 11.16 5.08683 11.16C4.11849 11.16 3.3335 11.945 3.3335 12.9133C3.3335 13.8817 4.11849 14.6667 5.08683 14.6667Z" fill="#DEDCDE"/>
  <path d="M10.7002 4.84C11.0469 4.84 11.3859 4.73716 11.6743 4.54451C11.9626 4.35185 12.1873 4.07801 12.32 3.75763C12.4527 3.43725 12.4875 3.08472 12.4198 2.7446C12.3522 2.40449 12.1852 2.09208 11.94 1.84687C11.6948 1.60166 11.3823 1.43467 11.0422 1.36702C10.7021 1.29937 10.3496 1.33409 10.0292 1.46679C9.70882 1.5995 9.43499 1.82423 9.24233 2.11256C9.04967 2.4009 8.94684 2.73989 8.94684 3.08666C8.94684 3.55168 9.13156 3.99764 9.46038 4.32646C9.78919 4.65527 10.2352 4.84 10.7002 4.84Z" fill="#DEDCDE"/>
  <path d="M10.7002 6.24666C10.3534 6.24666 10.0144 6.34949 9.72607 6.54215C9.43774 6.73481 9.21301 7.00864 9.0803 7.32902C8.9476 7.6494 8.91288 8.00194 8.98053 8.34205C9.04818 8.68216 9.21517 8.99458 9.46038 9.23979C9.70559 9.48499 10.018 9.65198 10.3581 9.71964C10.6982 9.78729 11.0508 9.75257 11.3711 9.61986C11.6915 9.48715 11.9654 9.26243 12.158 8.97409C12.3507 8.68576 12.4535 8.34677 12.4535 7.99999C12.4535 7.53498 12.2688 7.08901 11.94 6.7602C11.6112 6.43138 11.1652 6.24666 10.7002 6.24666Z" fill="#DEDCDE"/>
  <path d="M10.7002 11.16C10.3534 11.16 10.0144 11.2628 9.72607 11.4555C9.43774 11.6481 9.21301 11.922 9.0803 12.2424C8.9476 12.5627 8.91288 12.9153 8.98053 13.2554C9.04818 13.5955 9.21517 13.9079 9.46038 14.1531C9.70559 14.3983 10.018 14.5653 10.3581 14.633C10.6982 14.7006 11.0508 14.6659 11.3711 14.5332C11.6915 14.4005 11.9654 14.1758 12.158 13.8874C12.3507 13.5991 12.4535 13.2601 12.4535 12.9133C12.4535 12.4483 12.2688 12.0023 11.94 11.6735C11.6112 11.3447 11.1652 11.16 10.7002 11.16Z" fill="#DEDCDE"/>
</svg>
`,
  },
  {
    name: 'copyDisabled',
    icon: `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.009 6H7ZM5.002 8L5 20H15V8H5.002ZM9 6H17V16H19V4H9V6ZM7 11H13V13H7V11ZM7 15H13V17H7V15Z" fill="#DEDCDE"/>
</svg>
`,
  },
  {
    name: 'exportDisabled',
    icon: `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M5 12.5C5 12.813 5.461 13.358 6.53 13.893C7.914 14.585 9.877 15 12 15C14.123 15 16.086 14.585 17.47 13.893C18.539 13.358 19 12.813 19 12.5V10.329C17.35 11.349 14.827 12 12 12C9.173 12 6.65 11.348 5 10.329V12.5ZM19 15.329C17.35 16.349 14.827 17 12 17C9.173 17 6.65 16.348 5 15.329V17.5C5 17.813 5.461 18.358 6.53 18.893C7.914 19.585 9.877 20 12 20C14.123 20 16.086 19.585 17.47 18.893C18.539 18.358 19 17.813 19 17.5V15.329ZM3 17.5V7.5C3 5.015 7.03 3 12 3C16.97 3 21 5.015 21 7.5V17.5C21 19.985 16.97 22 12 22C7.03 22 3 19.985 3 17.5ZM12 10C14.123 10 16.086 9.585 17.47 8.893C18.539 8.358 19 7.813 19 7.5C19 7.187 18.539 6.642 17.47 6.107C16.086 5.415 14.123 5 12 5C9.877 5 7.914 5.415 6.53 6.107C5.461 6.642 5 7.187 5 7.5C5 7.813 5.461 8.358 6.53 8.893C7.914 9.585 9.877 10 12 10Z" fill="#DEDCDE"/>
</svg>
`,
  },
  {
    name: 'copy',
    icon: `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.009 6H7ZM5.002 8L5 20H15V8H5.002ZM9 6H17V16H19V4H9V6ZM7 11H13V13H7V11ZM7 15H13V17H7V15Z" fill="#27A6A4"/>
</svg>
`,
  },
  {
    name: 'export',
    icon: `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M5 12.5C5 12.813 5.461 13.358 6.53 13.893C7.914 14.585 9.877 15 12 15C14.123 15 16.086 14.585 17.47 13.893C18.539 13.358 19 12.813 19 12.5V10.329C17.35 11.349 14.827 12 12 12C9.173 12 6.65 11.348 5 10.329V12.5ZM19 15.329C17.35 16.349 14.827 17 12 17C9.173 17 6.65 16.348 5 15.329V17.5C5 17.813 5.461 18.358 6.53 18.893C7.914 19.585 9.877 20 12 20C14.123 20 16.086 19.585 17.47 18.893C18.539 18.358 19 17.813 19 17.5V15.329ZM3 17.5V7.5C3 5.015 7.03 3 12 3C16.97 3 21 5.015 21 7.5V17.5C21 19.985 16.97 22 12 22C7.03 22 3 19.985 3 17.5ZM12 10C14.123 10 16.086 9.585 17.47 8.893C18.539 8.358 19 7.813 19 7.5C19 7.187 18.539 6.642 17.47 6.107C16.086 5.415 14.123 5 12 5C9.877 5 7.914 5.415 6.53 6.107C5.461 6.642 5 7.187 5 7.5C5 7.813 5.461 8.358 6.53 8.893C7.914 9.585 9.877 10 12 10Z" fill="#27A6A4"/>
</svg>
`,
  },
  {
    name: 'ellipse',
    icon: `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M3 11.9764C3 12.1734 3.0388 12.3685 3.11418 12.5505C3.18956 12.7325 3.30005 12.8978 3.43934 13.0371C3.57863 13.1764 3.74399 13.2869 3.92598 13.3623C4.10796 13.4376 4.30302 13.4764 4.5 13.4764C4.69698 13.4764 4.89204 13.4376 5.07403 13.3623C5.25601 13.2869 5.42137 13.1764 5.56066 13.0371C5.69995 12.8978 5.81044 12.7325 5.88582 12.5505C5.9612 12.3685 6 12.1734 6 11.9764C6 11.7795 5.9612 11.5844 5.88582 11.4024C5.81044 11.2204 5.69995 11.0551 5.56066 10.9158C5.42137 10.7765 5.25601 10.666 5.07403 10.5906C4.89204 10.5152 4.69698 10.4764 4.5 10.4764C4.30302 10.4764 4.10796 10.5152 3.92598 10.5906C3.74399 10.666 3.57863 10.7765 3.43934 10.9158C3.30005 11.0551 3.18956 11.2204 3.11418 11.4024C3.0388 11.5844 3 11.7795 3 11.9764ZM10.5 11.9764C10.5 12.3743 10.658 12.7558 10.9393 13.0371C11.2206 13.3184 11.6022 13.4764 12 13.4764C12.3978 13.4764 12.7794 13.3184 13.0607 13.0371C13.342 12.7558 13.5 12.3743 13.5 11.9764C13.5 11.5786 13.342 11.1971 13.0607 10.9158C12.7794 10.6345 12.3978 10.4764 12 10.4764C11.6022 10.4764 11.2206 10.6345 10.9393 10.9158C10.658 11.1971 10.5 11.5786 10.5 11.9764ZM18 11.9764C18 12.3743 18.158 12.7558 18.4393 13.0371C18.7206 13.3184 19.1022 13.4764 19.5 13.4764C19.8978 13.4764 20.2794 13.3184 20.5607 13.0371C20.842 12.7558 21 12.3743 21 11.9764C21 11.5786 20.842 11.1971 20.5607 10.9158C20.2794 10.6345 19.8978 10.4764 19.5 10.4764C19.1022 10.4764 18.7206 10.6345 18.4393 10.9158C18.158 11.1971 18 11.5786 18 11.9764Z" fill="#27A6A4"/>
</svg>
`,
  },
  {
    name: 'home',
    icon: `
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.61 0.370008C10.4417 0.220981 10.2248 0.138702 10 0.138702C9.77524 0.138702 9.55825 0.220981 9.39 0.370008L0 8.91001H2.73V17.09C2.72905 17.2086 2.75153 17.3261 2.79615 17.4359C2.84077 17.5458 2.90665 17.6457 2.99 17.73C3.16293 17.9017 3.39633 17.9986 3.64 18H16.36C16.6037 17.9986 16.8371 17.9017 17.01 17.73C17.0934 17.6457 17.1592 17.5458 17.2038 17.4359C17.2485 17.3261 17.2709 17.2086 17.27 17.09V8.91001H20L10.61 0.370008Z" fill="white" fill-opacity="0.9"/>
    </svg>    
    `,
  },
  {
    name: 'admin-user',
    icon:
      '<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M13.5355 10.4645C12.5979 9.52678 11.3261 9 10 9C8.67392 9 7.40215 9.52678 6.46447 10.4645C5.52678 11.4021 5 12.6739 5 14V20H11.5278C10.5777 18.9385 10 17.5367 10 16C10 13.5478 11.4711 11.4391 13.579 10.5085C13.5646 10.4937 13.5501 10.4791 13.5355 10.4645Z" fill="white"/>' +
      '<path d="M3.008 13.656C3.03485 13.0968 3.12886 12.5428 3.288 12.006L3.119 12.02C2.2617 12.1139 1.46924 12.521 0.893635 13.1632C0.318029 13.8054 -0.000196625 14.6376 9.11477e-08 15.5V20H3V14L3.008 13.656Z" fill="white"/>' +
      '<path d="M5.26777 6.73223C4.79893 6.26339 4.16304 6 3.5 6C2.83696 6 2.20107 6.26339 1.73223 6.73223C1.26339 7.20107 1 7.83696 1 8.5C1 9.16304 1.26339 9.79893 1.73223 10.2678C2.20107 10.7366 2.83696 11 3.5 11C4.16304 11 4.79893 10.7366 5.26777 10.2678C5.73661 9.79893 6 9.16304 6 8.5C6 7.83696 5.73661 7.20107 5.26777 6.73223Z" fill="white"/>' +
      '<path d="M12.8284 1.17157C12.0783 0.421427 11.0609 0 10 0C8.93913 0 7.92172 0.421427 7.17157 1.17157C6.42143 1.92172 6 2.93913 6 4C6 5.06087 6.42143 6.07828 7.17157 6.82843C7.92172 7.57857 8.93913 8 10 8C11.0609 8 12.0783 7.57857 12.8284 6.82843C13.5786 6.07828 14 5.06087 14 4C14 2.93913 13.5786 1.92172 12.8284 1.17157Z" fill="white"/>' +
      '<path d="M12.595 16.812C12.4682 16.2784 12.4682 15.7226 12.595 15.189L11.603 14.616L12.603 12.884L13.595 13.457C13.9932 13.0799 14.4745 12.8017 15 12.645V11.5H17V12.645C17.532 12.803 18.012 13.085 18.405 13.457L19.397 12.884L20.397 14.616L19.405 15.189C19.5316 15.7223 19.5316 16.2777 19.405 16.811L20.397 17.384L19.397 19.116L18.405 18.543C18.0068 18.9201 17.5255 19.1983 17 19.355V20.5H15V19.355C14.4745 19.1983 13.9932 18.9201 13.595 18.543L12.603 19.116L11.603 17.384L12.595 16.812ZM16 17.5C16.3978 17.5 16.7794 17.342 17.0607 17.0607C17.342 16.7794 17.5 16.3978 17.5 16C17.5 15.6022 17.342 15.2206 17.0607 14.9393C16.7794 14.658 16.3978 14.5 16 14.5C15.6022 14.5 15.2206 14.658 14.9393 14.9393C14.658 15.2206 14.5 15.6022 14.5 16C14.5 16.3978 14.658 16.7794 14.9393 17.0607C15.2206 17.342 15.6022 17.5 16 17.5Z" fill="white"/>' +
      '</svg>',
  },
  {
    name: 'list-view',
    icon:
      '<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M5 0.5H19V2.5H5V0.5Z" fill="#2D8B93"/>' +
      '<path d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z" fill="#2D8B93"/>' +
      '<path d="M5 7H19V9H5V7Z" fill="#2D8B93"/>' +
      '<path d="M3 8C3 8.82843 2.32843 9.5 1.5 9.5C0.671573 9.5 0 8.82843 0 8C0 7.17157 0.671573 6.5 1.5 6.5C2.32843 6.5 3 7.17157 3 8Z" fill="#2D8B93"/>' +
      '<path d="M5 13.5H19V15.5H5V13.5Z" fill="#2D8B93"/>' +
      '<path d="M3 14.5C3 15.3284 2.32843 16 1.5 16C0.671573 16 0 15.3284 0 14.5C0 13.6716 0.671573 13 1.5 13C2.32843 13 3 13.6716 3 14.5Z" fill="#2D8B93"/>' +
      '</svg>',
  },
  {
    name: 'tile-view',
    icon:
      '<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"> ' +
      '<path d="M20 9.999V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H11V9.999H20ZM9 9.999V18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9.999H9ZM9 0V7.999H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H9ZM19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V7.999H11V0H19Z" fill="#454250"/>' +
      '</svg>',
  },
  {
    name: 'wait',
    icon:
      '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">' +
      '<g clip-path="url(#clip0_529_969)">' +
      '<path d="M9.4839 4.19741H8.51761C8.42921 4.19741 8.35689 4.26973 8.35689 4.35812V9.8887C8.35689 9.94093 8.381 9.98915 8.42319 10.0193L11.7439 12.4441C11.8163 12.4963 11.9167 12.4822 11.9689 12.4099L12.5435 11.6264C12.5977 11.5521 12.5817 11.4516 12.5093 11.4014L9.64462 9.33022V4.35812C9.64462 4.26973 9.57229 4.19741 9.4839 4.19741ZM13.8995 5.70812L17.0495 6.47754C17.15 6.50165 17.2484 6.42531 17.2484 6.32285L17.2645 3.07843C17.2645 2.94384 17.1098 2.8675 17.0053 2.95187L13.8393 5.42486C13.8153 5.44337 13.7971 5.46826 13.7867 5.49667C13.7763 5.52507 13.7741 5.55584 13.7804 5.58543C13.7867 5.61502 13.8012 5.64223 13.8223 5.66393C13.8434 5.68564 13.8701 5.70096 13.8995 5.70812ZM17.2685 11.757L16.1294 11.3653C16.0897 11.3517 16.0463 11.3541 16.0083 11.3721C15.9704 11.3902 15.9411 11.4223 15.9265 11.4617C15.8884 11.5641 15.8482 11.6646 15.806 11.765C15.4484 12.6108 14.9361 13.3722 14.2812 14.0251C13.6336 14.6747 12.8661 15.1926 12.0212 15.5499C11.1459 15.9199 10.2051 16.1098 9.25488 16.1083C8.29462 16.1083 7.36448 15.9215 6.48859 15.5499C5.64371 15.1926 4.87618 14.6747 4.22854 14.0251C3.57564 13.3722 3.06336 12.6108 2.70377 11.765C2.33576 10.8893 2.14722 9.94865 2.1493 8.99875C2.1493 8.03848 2.33613 7.10634 2.70778 6.23044C3.06537 5.38468 3.57765 4.6233 4.23256 3.9704C4.8802 3.32075 5.64773 2.80292 6.49261 2.44562C7.36448 2.07397 8.29662 1.88714 9.25689 1.88714C10.2172 1.88714 11.1473 2.07397 12.0232 2.44562C12.8681 2.80292 13.6356 3.32075 14.2832 3.9704C14.4881 4.17732 14.681 4.39227 14.8578 4.61928L16.0591 3.6791C14.4781 1.65812 12.0172 0.358345 9.25287 0.360354C4.43948 0.362363 0.574302 4.27174 0.622517 9.08714C0.670731 13.8182 4.51783 17.6371 9.25689 17.6371C12.9835 17.6371 16.1576 15.2746 17.3669 11.9659C17.3971 11.8816 17.3529 11.7871 17.2685 11.757Z" fill="#454250"/>' +
      '</g>' +
      '<defs>' +
      '<clipPath id="clip0_529_969">' +
      '<rect width="18" height="18" fill="white"/>' +
      '</clipPath>' +
      '</defs>' +
      '</svg>',
  },
  {
    name: 'edit',
    icon:
      '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M9.9 3.858L14.142 8.101L4.242 18H0V13.757L9.9 3.857V3.858ZM11.314 2.444L13.435 0.322C13.6225 0.134528 13.8768 0.029213 14.142 0.029213C14.4072 0.029213 14.6615 0.134528 14.849 0.322L17.678 3.151C17.8655 3.33853 17.9708 3.59284 17.9708 3.858C17.9708 4.12316 17.8655 4.37747 17.678 4.565L15.556 6.686L11.314 2.444Z" fill="#27A6A4"/>' +
      '</svg>',
  },
  {
    name: 'editDisabled',
    icon:
      '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M9.9 3.858L14.142 8.101L4.242 18H0V13.757L9.9 3.857V3.858ZM11.314 2.444L13.435 0.322C13.6225 0.134528 13.8768 0.029213 14.142 0.029213C14.4072 0.029213 14.6615 0.134528 14.849 0.322L17.678 3.151C17.8655 3.33853 17.9708 3.59284 17.9708 3.858C17.9708 4.12316 17.8655 4.37747 17.678 4.565L15.556 6.686L11.314 2.444Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'clone',
    icon:
      '<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.009 4H4ZM2.002 6L2 18H12V6H2.002ZM6 4H14V14H16V2H6V4ZM4 9H10V11H4V9ZM4 13H10V15H4V13Z" fill="#27A6A4"/>' +
      '</svg>',
  },
  {
    name: 'cloneDisabled',
    icon:
      '<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.009 4H4ZM2.002 6L2 18H12V6H2.002ZM6 4H14V14H16V2H6V4ZM4 9H10V11H4V9ZM4 13H10V15H4V13Z" fill="#DEDCDE"/>' +
      '</svg>',
  },
  {
    name: 'download',
    icon:
      '<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z" fill="#27A6A4"/>' +
      '</svg>',
  },
  {
    name: 'downloadDisabled',
    icon:
      '<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'share',
    icon: `<svg width="1em" height="1em" viewBox="0 0 19 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" data-icon="custom:share" aria-hidden="true">
<path d="M11.5762 15.271L6.4662 12.484C5.97569 12.9709 5.35201 13.3017 4.67379 13.4346C3.99557 13.5676 3.29316 13.4968 2.65513 13.2311C2.01709 12.9654 1.47199 12.5168 1.08855 11.9418C0.705108 11.3668 0.500488 10.6911 0.500488 9.99999C0.500488 9.30886 0.705108 8.63319 1.08855 8.05818C1.47199 7.48317 2.01709 7.03455 2.65513 6.76889C3.29316 6.50322 3.99557 6.43241 4.67379 6.56536C5.35201 6.69832 5.97569 7.02909 6.4662 7.51599L11.5762 4.72899C11.4009 3.90672 11.5275 3.04885 11.9328 2.31224C12.3381 1.57563 12.9949 1.00952 13.7833 0.717422C14.5717 0.425324 15.4388 0.426763 16.2262 0.721475C17.0136 1.01619 17.6686 1.58448 18.0714 2.32243C18.4742 3.06037 18.598 3.91866 18.42 4.74034C18.242 5.56202 17.7742 6.29218 17.1021 6.79729C16.43 7.3024 15.5986 7.54871 14.7598 7.49119C13.9211 7.43366 13.131 7.07613 12.5342 6.48399L7.4242 9.27099C7.52613 9.75164 7.52613 10.2483 7.4242 10.729L12.5342 13.516C13.131 12.9238 13.9211 12.5663 14.7598 12.5088C15.5986 12.4513 16.43 12.6976 17.1021 13.2027C17.7742 13.7078 18.242 14.438 18.42 15.2596C18.598 16.0813 18.4742 16.9396 18.0714 17.6776C17.6686 18.4155 17.0136 18.9838 16.2262 19.2785C15.4388 19.5732 14.5717 19.5747 13.7833 19.2826C12.9949 18.9905 12.3381 18.4243 11.9328 17.6877C11.5275 16.9511 11.4009 16.0933 11.5762 15.271Z" fill="#27A6A4"></path>
</svg>`,
  },
  {
    name: 'shareDisabled',
    icon:
      '<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M11.5762 15.271L6.4662 12.484C5.97569 12.9709 5.35201 13.3017 4.67379 13.4346C3.99557 13.5676 3.29316 13.4968 2.65513 13.2311C2.01709 12.9654 1.47199 12.5168 1.08855 11.9418C0.705108 11.3668 0.500488 10.6911 0.500488 9.99999C0.500488 9.30886 0.705108 8.63319 1.08855 8.05818C1.47199 7.48317 2.01709 7.03455 2.65513 6.76889C3.29316 6.50322 3.99557 6.43241 4.67379 6.56536C5.35201 6.69832 5.97569 7.02909 6.4662 7.51599L11.5762 4.72899C11.4009 3.90672 11.5275 3.04885 11.9328 2.31224C12.3381 1.57563 12.9949 1.00952 13.7833 0.717422C14.5717 0.425324 15.4388 0.426763 16.2262 0.721475C17.0136 1.01619 17.6686 1.58448 18.0714 2.32243C18.4742 3.06037 18.598 3.91866 18.42 4.74034C18.242 5.56202 17.7742 6.29218 17.1021 6.79729C16.43 7.3024 15.5986 7.54871 14.7598 7.49119C13.9211 7.43366 13.131 7.07613 12.5342 6.48399L7.4242 9.27099C7.52613 9.75164 7.52613 10.2483 7.4242 10.729L12.5342 13.516C13.131 12.9238 13.9211 12.5663 14.7598 12.5088C15.5986 12.4513 16.43 12.6976 17.1021 13.2027C17.7742 13.7078 18.242 14.438 18.42 15.2596C18.598 16.0813 18.4742 16.9396 18.0714 17.6776C17.6686 18.4155 17.0136 18.9838 16.2262 19.2785C15.4388 19.5732 14.5717 19.5747 13.7833 19.2826C12.9949 18.9905 12.3381 18.4243 11.9328 17.6877C11.5275 16.9511 11.4009 16.0933 11.5762 15.271Z" fill="#DEDCDE"/>' +
      '</svg>',
  },
  {
    name: 'delete',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M7 4V2H17V4H22V6H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="#27A6A4"/>
</svg>`,
  },
  {
    name: 'deleteDisabled',
    icon:
      '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M15 2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5V0H15V2ZM7 7V15H9V7H7ZM11 7V15H13V7H11Z" fill="#27A6A4" opacity="0.5"/>' +
      '</svg>',
  },
  {
    name: 'more-dots',
    icon: `
      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.97644C-4.1511e-09 2.17342 0.0387985 2.36848 0.114181 2.55047C0.189563 2.73245 0.300052 2.89781 0.43934 3.0371C0.578628 3.17639 0.743987 3.28688 0.925975 3.36226C1.10796 3.43764 1.30302 3.47644 1.5 3.47644C1.69698 3.47644 1.89204 3.43764 2.07403 3.36226C2.25601 3.28688 2.42137 3.17639 2.56066 3.0371C2.69995 2.89781 2.81044 2.73245 2.88582 2.55047C2.9612 2.36848 3 2.17342 3 1.97644C3 1.77946 2.9612 1.5844 2.88582 1.40242C2.81044 1.22043 2.69995 1.05507 2.56066 0.91578C2.42137 0.776492 2.25601 0.666003 2.07403 0.590621C1.89204 0.515239 1.69698 0.47644 1.5 0.47644C1.30302 0.47644 1.10796 0.515239 0.925975 0.590621C0.743987 0.666003 0.578628 0.776492 0.43934 0.91578C0.300052 1.05507 0.189563 1.22043 0.114181 1.40242C0.0387985 1.5844 -4.1511e-09 1.77946 0 1.97644ZM7.5 1.97644C7.5 2.37427 7.65804 2.7558 7.93934 3.0371C8.22064 3.3184 8.60218 3.47644 9 3.47644C9.39783 3.47644 9.77936 3.3184 10.0607 3.0371C10.342 2.7558 10.5 2.37427 10.5 1.97644C10.5 1.57862 10.342 1.19708 10.0607 0.91578C9.77936 0.634475 9.39783 0.47644 9 0.47644C8.60218 0.47644 8.22064 0.634475 7.93934 0.91578C7.65804 1.19708 7.5 1.57862 7.5 1.97644ZM15 1.97644C15 2.37427 15.158 2.7558 15.4393 3.0371C15.7206 3.3184 16.1022 3.47644 16.5 3.47644C16.8978 3.47644 17.2794 3.3184 17.5607 3.0371C17.842 2.7558 18 2.37427 18 1.97644C18 1.57862 17.842 1.19708 17.5607 0.91578C17.2794 0.634475 16.8978 0.47644 16.5 0.47644C16.1022 0.47644 15.7206 0.634475 15.4393 0.91578C15.158 1.19708 15 1.57862 15 1.97644Z" fill="#27A6A4"/>
      </svg>
    `,
  },
  {
    name: 'filter',
    icon: `
 <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M7.64631 12H11.8281V10H7.64631V12ZM0.328125 0V2H19.1463V0H0.328125ZM3.46449 7H16.0099V5H3.46449V7Z" fill="currentColor" fill-opacity="1"/>
 </svg>
 `,
  },
  {
    name: 'dialog-warning',
    icon: `
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_25664_112571)">
      <path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" fill="#E3A900"/>
      <path d="M9.82129 15.3214C9.82129 15.634 9.94546 15.9338 10.1665 16.1548C10.3875 16.3758 10.6873 16.5 10.9999 16.5C11.3124 16.5 11.6122 16.3758 11.8332 16.1548C12.0543 15.9338 12.1784 15.634 12.1784 15.3214C12.1784 15.0089 12.0543 14.7091 11.8332 14.4881C11.6122 14.267 11.3124 14.1429 10.9999 14.1429C10.6873 14.1429 10.3875 14.267 10.1665 14.4881C9.94546 14.7091 9.82129 15.0089 9.82129 15.3214ZM10.4106 12.5714H11.5891C11.6972 12.5714 11.7856 12.483 11.7856 12.375V5.69643C11.7856 5.58839 11.6972 5.5 11.5891 5.5H10.4106C10.3025 5.5 10.2141 5.58839 10.2141 5.69643V12.375C10.2141 12.483 10.3025 12.5714 10.4106 12.5714Z" fill="#E3A900"/>
      </g>
      <defs>
      <clipPath id="clip0_25664_112571">
      <rect width="22" height="22" fill="white"/>
      </clipPath>
      </defs>
      </svg>
    `,
  },
  {
    name: 'dialog-success',
    icon: `
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM12.63 9.14501C13.1266 8.64237 13.6236 8.13922 14.1222 7.63606C15.0377 6.70687 16.4725 8.12799 15.5433 9.05719C14.6825 9.92486 13.825 10.7925 12.9676 11.6602C12.1101 12.5279 11.2527 13.3957 10.3918 14.2634C10.0092 14.6597 9.36691 14.646 8.97064 14.2634C8.50603 13.8056 8.038 13.3478 7.56997 12.8901C7.10198 12.4323 6.63398 11.9746 6.1694 11.5168C5.24021 10.615 6.66133 9.18016 7.59052 10.0957C7.93897 10.4373 8.284 10.7755 8.62903 11.1137C8.97406 11.4519 9.31909 11.7901 9.66754 12.1317C10.6584 11.1409 11.6432 10.1439 12.63 9.14501Z" fill="#00AA67"/>
    </svg>
    `,
  },
];
