import { InMemoryDbService } from 'angular-in-memory-web-api';

export class InMemoryService implements InMemoryDbService {
  createDb(): object {
    const LauchBarData = {
      status: 'SUCCESS',
      applications: {
        expiry: {},
        applications: [
          {
            name: 'Patient Analytics Cloud',
            title: 'Patient Analytics Cloud',
            url: 'https://pac.dev.zsservices.com/',
          },
        ],
        signOut:
          'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/logout.aspx',
        myAccount:
          'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/MyAccount.aspx',
      },
    };

    const UserDetailData = {
      auth_status: 'success',
      username: 'zs\\aa17567',
      session_details: null,
      oauth_cookie: null,
      permissions: ['datasets', 'cohort', 'approver', 'concepts', 'admin'],
    };

    const HomeKPIData = {
      status: 'success',
      cohortLibrary: 0,
      workspace: 10,
    };

    const CohortListData = {
      status: 'success',
      bodyContent: [
        {
          chrt_nm: 'Lung Cancer',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 2',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 3',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 4',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 5',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 6',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 7',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 8',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 9',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 10',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
        {
          chrt_nm: 'Lung Cancer 11',
          status: 'Completed',
          therapeutic_area: 'Oncology',
          no_of_patients: [
            { dataset: 'Dataset 1', count: 100 },
            { dataset: 'Dataset 2', count: 200 },
          ],
          total_size: '1.23GB',
          collaborators: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
          upd_ts: '06/28/2019 10:27:35',
          upd_by: 'Adam Smith',
          crea_ts: '06/28/2019 10:27:35',
          crea_by: 'Kim Smith',
          global_domain: 'Concept Name 1',
          // datasets: ["Dataset 1", "Dataset 2"],
        },
      ],
      filters: {
        status: ['Completed', 'In Progress', 'Export in Progress'],
        therapeutic_area: ['Oncology'],
        global_domain: ['Concept 1', 'Concept 2'],
        no_of_patients: ['Data set 1', 'Data Set 2'],
      },
    };

    return {
      // 'launch-bar': LauchBarData,
      // 'user-details': UserDetailData,
      // 'home-kpi': HomeKPIData,
      // cohort_list: CohortListData,
    };
  }
}
