import { NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ApiInterceptor, AuthInterceptor } from "./shared/interceptors";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  RDAlertModule,
  RDButtonModule,
  RDHeaderModule,
  RDModalModule,
  RDSiderModule,
  RDSpinModule,
} from "@zs-ca/angular-cd-library";
import { HeaderComponent } from "./layout/header/header.component";
import { SiderComponent } from "./layout/sider/sider.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzNotificationService } from "ng-zorro-antd/notification";
import {
  DataFetchService,
  TransactionManagerService,
  CustomIconService,
  CommonService,
} from "./shared/services";
import { InMemoryService } from "./shared/services/in-memory.service";
import { RedirectionService } from "./shared/services/redirection.service";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import zh from "@angular/common/locales/zh";

registerLocaleData(en);
registerLocaleData(zh);
@NgModule({
  declarations: [
    AppComponent,
    SiderComponent,
    HeaderComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpClientInMemoryWebApiModule.forRoot(InMemoryService, {
      dataEncapsulation: false,
      passThruUnknownUrl: true,
    }),
    NzIconModule,
    RDButtonModule,
    RDHeaderModule,
    RDModalModule,
    RDSiderModule,
    RDSpinModule,
    RDAlertModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: NZ_I18N,
      useFactory: () => {
        return en_US;
      },
      deps: [LOCALE_ID],
    },
    TransactionManagerService,
    DataFetchService,
    CustomIconService,
    InMemoryService,
    RedirectionService,
    CommonService,
    NzNotificationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
